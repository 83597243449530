//
// Stylesheet root file.
//

// Other styles
@import 'settings';

// Settings
@import './components/index';

#app {
    font-family: 'Nunito Sans', sans-serif;
    position: relative;

    .bigtext {
        font-weight: bold;
        font-family: 'Nunito Sans', sans-serif;
    }
}

p,
h1,
h2,
h3,
h4,
span,
label {
    font-family: 'Nunito Sans', sans-serif;
}

a {
    text-decoration: none;
    color: $primary;
}

html,
body,
#app {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: $white;
    font-family: 'Nunito Sans', sans-serif;
}

html {
	scroll-behavior: smooth;
}
