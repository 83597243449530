.filedrop {
    &-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bigtext {
            margin: 2em 0;
        }
    }

    &-files {
        display: flex;
        flex-direction: column;
        text-align: center;
        div {
            display: flex;
            align-items: center;
        }

        .search {
            height: 3em;
            padding: 0 0 0 0.5em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            background: $white;
        }
    }

    &-drop {
        width: 31.25em;
        height: 25em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $white;
        color: $gray;
        line-height: 1.4em;

        i {
            font-size: 9em;
            color: $gray;
            opacity: 0.1;
            cursor: pointer;
        }
    }

    &-fakelink {
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
    }
}
