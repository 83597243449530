//
// Define variables, mixins
//
// Font
@import url('https://fonts.googleapis.com/css?family=Nunito Sans:400,700');
@import url('https://fonts.googleapis.com/css2?family=Flamenco:wght@300;400&display=swap');
// MaterialUI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// Normalize.css
@import url('https://necolas.github.io/normalize.css/8.0.1/normalize.css');

//RC-slider
@import '../../node_modules/rc-slider/assets/index.css';

// Color
$black: #000;
$white: #fff;
$red: rgb(255, 60, 60);
$primary: #000000;
$light-gray: rgb(201, 197, 197);
$gray: #707070;
$dark: #121212;
$blue: #009cb5;
$blue60: #009cb599;
