.login {
    &-container {
        display: flex;
        flex: 1;
    }

    &-left {
        flex: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            height: 100%;
        }
    }

    &-texts {
        margin: 1.8125em 0 3.75em 0;
        display: flex;
        justify-content: space-between;
    }

    &-registerText {
        display: flex;
        justify-content: center;

        a {
            margin-left: 0.2em;
        }
    }

    &-right {
        padding: 1em;
        flex: 1;
        display: flex;
        justify-content: center;

        .right-container {
            flex: 1;
            max-width: 22.5em;
            display: flex;
            flex-direction: column;
        }

        h1 {
            margin: 10em 0 4em 0;
        }

        .login {
            margin-bottom: 3.75em;
        }
    }
}

@media screen and (max-width: 760px) {
    .login-right {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: $white;
        margin: auto;
    }
}
