.loadingContainer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
}

.loadingBar {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    span {
        color: white;
    }
}
.loadingCircle {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    span {
        color: white;
    }
}
