//
// Navigation stylesheet
//
.nav {
    height: 4.4em;
    min-height: 4.4em;
    width: 100%;
    min-width: 1200px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    &-links {
        display: flex;
    }
    &-home {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: bold;
        font-size: 1.25em;
        color: $black;
        margin: auto 2.6em;
    }
    &-link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 5.5em;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: bold;
        color: $black;
        &-active {
            background-color: $blue;
            color: $white;
        }
    }
    &-account {
        margin-right: 3.75em;
        display: flex;
        align-items: center;
        &-img {
            i {
                font-size: 50px;
                margin-right: 8px;
            }
        }
        &-name {
            line-height: 22px;
            margin-right: 0.5em;
        }
        &-option {
            i {
                width: 1.5em;
                height: 1.5em;
                cursor: pointer;
            }
        }
    }
}
