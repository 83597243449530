.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.loadingLogo {
  width: 10em;
  margin: 2em;
}
.logo {
  display: block;
  width: 10em;
  margin: 0 auto;
}
.logoAdmin {
  display: block;
  width: 7em;
  margin: 0 auto;
}

::-webkit-scrollbar {
    display: 'none'
}

::-webkit-scrollbar-track {
  display: 'none'
}

::-webkit-scrollbar-thumb {
  display: 'none'
}

// Hide default leaflet-draw toolbar items
.grecaptcha-badge,
.leaflet-draw-draw-rectangle,
.leaflet-draw-draw-marker,
.leaflet-draw-actions {
    display: none !important;
}

.iframe-holder {
    height: 600px;
}

#trackview-chart {
    flex: 1;
    width: 100%;
}
