.button {
    flex: 1;
    padding: 0 2em;
    border-radius: 4px;
    margin: 0;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    &:focus {
        outline: none;
    }
    &-none {
        background-color: white;
    }
    &-normal {
        background-color: $blue;
        color: $white;
    }
    &-slick {
        background-color: $blue;
        color: $white;
        height: 2.3em;
        padding: 0 1.15em;
        font-size: 0.88em;
    }
}
