.text {
    font-size: 1em;
    color: black;
    font-weight: 400;

    &-small {
        font-size: 0.8em;
    }

    &-small-grey {
        font-size: 0.7em;
        color: #a6a7b9;
        margin: 0;
    }

    &-bold {
        font-weight: 600;
        font-size: 1.1em;
        margin-top: 0;
    }

    &-big {
        font-weight: 400;
        font-size: 1.2em;
        margin-bottom: 0.3em;
        margin-top: 0.5em;
        text-transform: uppercase;
    }
}
